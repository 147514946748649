import React from 'react'
import './SphericalRoller1.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import sph from '../../../../../images/Products2/types/spherical roller cover.png'
const SphericalRoller1 = () => {
  return (
    <div className='Spheri bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='Spheri-parent'>
          <div className='Spheri-lft'>
            <h1 className='Spheri-h2'>Overview</h1>
            <img src={sph} alt=" Spherical Roller-img" className='Spheri-img'/>
          </div>
          <div className='Spheri-rght'>
            
         <p className='Spheri-p'>
         Spherical roller bearings have two rows of rollers and a common sphered raceway
in the outer ring. They can handle heavy radial and axial loads and are self-aligning.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default SphericalRoller1;