import React from 'react'
import Home1 from '../Home1/Home1'
import Home2 from '../Home2/Home2'
import Home3 from '../Home3/Home3'
import Home4 from '../Home4/Home4'
import Home5 from '../Home5/Home5'

export const Home = () => {
  return (
    <div>
        <Home1/>
        <Home2/>
      <Home3/>
      <Home4/>
      <Home5/>
    </div>
  )
}
