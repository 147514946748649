import React from 'react'
import './DeepGroove1.css';
import bg from '../../../../../images/products/sec 2/bg.png';
import deep from '../../../../../images/Products2/main-png/deep groove.png'
// import Deeptype1 from './Deeptypes/DeepType1.jsx';

const DeepGroove1 = () => {
  return (
    <div className='Deep bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='deep-parent'>
          <div className='deep-lft'>
            <h1 className='deep-h2'>Overview</h1>
            <img src={deep} alt="" className='deep-img'/>
          </div>
          <div className='deep-rght'>
            
         <p className='deep-p'>
         The most popular kind of ball bearings are deep groove ball bearings. Their inner
and outer rings feature deep raceway grooves that enable them to withstand axial and radial
loads in both directions. An outer ring, an inner ring, a set of steel balls, and a cage to hold the
balls in place make up their straightforward construction.
</p>
 
          </div>
          
        </div>
      </div>
  )
}

export default DeepGroove1;