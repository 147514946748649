import React from 'react'
import './Spherical.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import SphericalRoller1 from './SphericalRoller/SphericalRoller1.jsx';
import SphericalType1 from './Sphericaltypes/SphericalType1.jsx';

const Spherical = () => {
  return (
    <div className='SphMain bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='SphMain-h1'>Spherical Roller Bearings</h1>
        <SphericalRoller1/>
        <SphericalType1/>
      </div>
  )
}

export default Spherical;