import React from 'react'
import './Angular.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import ang from '../../../../images/Products2/main-png/deep groove.png'
import AngularBall from './Angular/AngularBall.jsx';
import Angulartype1 from './Angulartypes/AngularType1.jsx';

const Angular = () => {
  return (
    <div className='ang bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='angu-h1'>Angular Contact Ball Bearings</h1>
       <AngularBall/>
       <Angulartype1/>
      </div>
  )
}

export default Angular;