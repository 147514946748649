import React from 'react'
import About1 from '../About1/About1'
import About2 from '../About2/About2'
import About3 from '../About3/About3'

export const About = () => {
  return (
    <div>
        <About1/>
        <About2/>
        <About3/>
    </div>
  )
}
