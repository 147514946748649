import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import downArrow from '../../../images/Products2/sidebar/down-arrow.svg';
import upArrow from '../../../images/Products2/sidebar/up-arrow.svg';

const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState('ballBearings'); // Set default open submenu
  const navigate = useNavigate();

  const toggleMenu = (menuName) => {
    if (openMenu === menuName) {
      setOpenMenu(null); // Close the menu if it's already open
    } else {
      setOpenMenu(menuName); // Open the selected menu
    }
  };

  const getMenuStyle = (menuName) => {
    return {
      color: openMenu === menuName ? '#dc0005' : 'white',
    };
  };

  const handleNavLinkClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to top
  };

  return (
    <div className="sidebar">
      <div className="menu-section">
        <h1 className='sidebar-h1'>Products</h1>
        <h2 onClick={() => toggleMenu('ballBearings')} className='menu-h1' style={getMenuStyle('ballBearings')}>
          Ball Bearings
          <img src={openMenu === 'ballBearings' ? upArrow : downArrow} alt="Toggle" className="arrow-icon" />
        </h2>
        {openMenu === 'ballBearings' && (
          <div className="submenu">
            <div onClick={() => handleNavLinkClick('/products/deep-groove')} className="menu-item"><h1 className='menu-h2'>Deep Groove Ball Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/angular-ball')} className="menu-item"><h1 className='menu-h2'>Angular Contact Ball Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/self-aligning')} className="menu-item"><h1 className='menu-h2'>Self-Aligning Ball Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/thrust-ball')} className="menu-item"><h1 className='menu-h2'>Thrust Ball Bearings</h1></div>
          </div>
        )}
      </div>

      <div className="menu-section">
        <h2 onClick={() => toggleMenu('rollerBearings')} className='menu-h1' style={getMenuStyle('rollerBearings')}>
          Roller Bearings
          <img src={openMenu === 'rollerBearings' ? upArrow : downArrow} alt="Toggle" className="arrow-icon" />
        </h2>
        {openMenu === 'rollerBearings' && (
          <div className="submenu">
            <div onClick={() => handleNavLinkClick('/products/cylindrical-roller')} className="menu-item"><h1 className='menu-h2'>Cylindrical Roller Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/tapered-roller')} className="menu-item"><h1 className='menu-h2'>Tapered Roller Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/spherical-roller')} className="menu-item"><h1 className='menu-h2'>Spherical Roller Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/thrust-roller')} className="menu-item"><h1 className='menu-h2'>Thrust Roller Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/needle-roller')} className="menu-item"><h1 className='menu-h2'>Needle Roller Bearings</h1></div>
          </div>
        )}
      </div>

      <div className="menu-section">
        <h2 onClick={() => toggleMenu('envBearings')} className='menu-h1' style={getMenuStyle('envBearings')}>
          Environmental Bearings
          <img src={openMenu === 'envBearings' ? upArrow : downArrow} alt="Toggle" className="arrow-icon" />
        </h2>
        {openMenu === 'envBearings' && (
          <div className="submenu">
            <div onClick={() => handleNavLinkClick('/products/high-temperature')} className="menu-item"><h1 className='menu-h2'>High Temperature Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/ceramic-bearings')} className="menu-item"><h1 className='menu-h2'>Ceramic Bearings</h1></div>
            <div onClick={() => handleNavLinkClick('/products/corrosion-bearings')} className="menu-item"><h1 className='menu-h2'>Corrosion Resistant Bearings</h1></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
