import React  from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import './Corrosiontypes.css';

const Corrosiontypes = () => {
  const sections = [
    {
      content: (
        <>
          <div className='cor-list'>
            <h1>Features:</h1>
            <ul>
            <li><span> Resistant to Corrosion:</span> Made from stainless steel, ceramic, or coated with
anti-corrosive materials to withstand harsh environments.
            </li>
            <li><span> Durable and Long-Lasting: </span> Enhanced resistance to chemical and environmental
            damage extends the bearing's operational life.
            </li>
            </ul>
            <h1>Applications:</h1>
            <ul>
            <li><span> Marine Equipment:  </span> Used in maritime environments where saltwater and humidity can
            cause rapid corrosion.
            </li>
            <li><span> Chemical Processing Plants: </span>  Essential for machinery exposed to aggressive
            </li>
            <li><span> Food and Beverage Industry:  </span> Utilized in processing equipment where hygiene and
resistance to cleaning agents are critical.
            </li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span> Reliability:   </span>  Provides consistent performance in environments that would otherwise
            degrade standard bearings.
            </li>
            <li><span> Reduced Maintenance:  </span>  Enhanced materials and coatings reduce the need for frequent
maintenance and replacement.
            </li>
            <li><span> Versatility:  </span>   Suitable for a wide range of applications, from industrial machinery to
            delicate processing equipment.
            </li>
            </ul>
          </div>
        </>
      ),
    },


  ];

  return (
    <>
    <div className='cortype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='Coro-typeh1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='Coro-main'>
            <div className='Coro-lft'>
                {section.content}
            </div>
        </div>
      ))}
    </div>
    <div className='Coros-div'>
<div className='Cors-divs flex  bg-center bg-cover bg-no-repeat  ' style={{ backgroundImage: `url(${itembg})` }}>

<div className='Cors3-divs ' >
  <h1 className='Cors3-divsh1'>get quality bearings now!</h1>
  <p className='Corosin-p'>ANK Overseas:Dedicated to quality,innovative . 
  ANK Overseas will give the greatest degree of
durability and performance.</p>
  <a href='/contactus'>
  <button className='Corosin-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
        get quote
       </button>
       </a>
</div>

</div>
</div>
    </>
  );
};

export default Corrosiontypes;
