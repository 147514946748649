import React from 'react'
import './CeramicBear.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import ceramic from '../../../../../images/Products2/types/ceramic cover.png'
const CeramicBear = () => {
  return (
    <div className='ceramic bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='ceramic-parent'>
          <div className='ceramic-lft'>
            <h1 className='ceramic-h2'>Overview</h1>
            <img src={ceramic} alt="Needle Bearing" className='ceramic-img'/>
          </div>
          <div className='ceramic-rght'>
            
         <p className='ceramic-p'>
         Ceramic bearings are composed of ceramic rings and balls, which are frequently
combined with self-lubricating cages made of fluoropolymers. These bearings are very resistant
to corrosion, wear, and high temperatures, making them perfect for precise applications that
need minimal magnetic interference or contamination.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default CeramicBear;