import React, { useState } from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import type1 from '../../../../../images/Products2/types/Single-Row Angular.png';
import type2 from '../../../../../images/Products2/types/Matched Angular.png';
import type3 from '../../../../../images/Products2/types/Double-Row_Angular.png';
import type4 from '../../../../../images/Products2/types/Four-point-contact.png';
import openIcon from '../../../../../images/Products2/sidebar/plus.svg';
import closeIcon from '../../../../../images/Products2/sidebar/minus.svg';
import './AngularType1.css';

const Angulartype1 = () => {
  const sections = [
    {
      title: 'Single-Row Angular Contact Ball Bearings',
      image: type1,
      content: (
        <>
          <p className='rowAngu-p1'>Combined radial and axial loads are supported by single row angular contact ball
          bearings.The contact angle, or the angle formed by the line between the ball's contact points
          with the raceways and the radial plane of the bearing, raises the load-carrying capacity..</p>
          <div className='Angutype-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Pumps:</span> Perfect for pumps in situations with significant axial loads.

              </li>
              <li><span>Gearboxes:</span> Applied to gearboxes for exact load distribution and alignment.
              </li>
              <li><span>Clutches:</span> Frequently seen in high-precision clutch mechanisms.
              </li>
              <li><span>Electric Motors:</span> Suitable for motors operating at high speeds .
              </li>
               </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>Precision:</span>  Improved load distribution for precise applications.
            </li>
            <li><span>Greater Axial Load Capacity:</span> Can handle higher axial loads compared to deep groove
            ball bearings.
              </li>
              <li><span>Speed: </span> Highly suitable for applications requiring high speeds.
              </li>
              <li><span>Precision:</span>  More even load distribution for targeted uses.
              </li>
              </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Matched Angular Contact Ball Bearings',
      image: type2, 
      content: (
        <>
          <p className='rowAngu-p1'>
          To manage axial stresses in both directions, matched angular contact ball
bearings are utilised in sets or pairs. To provide appropriate load sharing and minimal
deformation, they are preloaded.
</p>
          <div className='Angutype-list'>
          <h1>Applications:</h1>
            <ul>
            <li><span>Machine Tool Spindles:</span>  offer a high level of precision and rigidity.
            </li>
            <li><span>Precision Instruments:</span> applied in instruments that need to be highly accurate.
            </li>
            <li><span>Automotive Applications: </span>  Commonly found in suspension and steering systems.
            </li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>High Precision: </span>  Ensures precise load distribution.
            </li>
            <li><span>Load Distribution: </span>   Better load distribution reduces deformation.
            </li>
            <li><span>Stiffness: </span>  Enhance rigidity in applications that require high-precision.
            </li>
             </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Double-Row Angular Contact Ball Bearings',
      image: type3, 
      content: (
        <>
          <p className='rowAngu-p1'>
          Double row angular contact ball bearings are two single row bearings arranged
          back-to-back. This configuration allows them to handle higher radial and axial loads.
          </p>
          <div className='Angutype-list'>
          <h1>Applications:</h1>
            <ul>
            <li><span>Agricultural Machinery: </span>It is utilised in various machines such as tractors.
            </li>
            <li><span>Compressors: </span>  Suitable for high-load compressor applications.
            </li>
            <li><span>Construction Equipment:  </span>Common in heavy-duty construction vehicles.
            </li>
              </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>High Load Capacity: </span> It can manage and handle higher loads than single row bearings.
            </li>
            <li><span>Stiffness: </span>Increased stiffness for heavy-duty applications.
            </li>
            <li><span>Compact Design: </span> Its Space-saving design is highly suitable for compact applications.
            </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Four Point Contact Ball Bearings',
      image: type4, 
      content: (
        <>
          <p className='rowAngu-p1'>
          Four point contact ball bearings are capable of supporting radial loads as well as
axial loads in both directions. The design allows for a high number of balls and high load
capacity.
           </p>
          <div className='Angutype-list'>
            <h1>Applications:</h1>
            <ul>
            <li><span>Industrial Gearboxes: </span> Fit for high-load gearboxes.
            </li>
            <li><span>Aerospace Applications:  </span>  Perfect for aerospace components requiring high load
            capacity.
            </li>
            <li><span>Robotics:  </span>  Applies in robotic arms and mechanisms.
            </li>
             </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>High Axial Load Capacity: </span>  Supports large axial loads in both directions.
            </li>
            <li><span>Compact:  </span> It has a space-saving design with high load capacity.
            </li>
            <li><span>Versatile: </span> Suitable for combined loads.
            </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  const [openIndexes, setOpenIndexes] = useState(sections.map((_, index) => index)); // Initially set all indexes as open

  const toggleVisibility = (index) => {
    setOpenIndexes((prevIndexes) => 
      prevIndexes.includes(index) 
        ? prevIndexes.filter((i) => i !== index) // Close the section if it's already open
        : [...prevIndexes, index] // Open the section if it's closed
    );
  };

  return (
    <>
    <div className='Angutype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='Angutype-h1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='Anglft1-main'>
          <div className='angul1-left1'>
            <h2 className='Angutyp-h2'>{section.title}</h2>
            <img 
              src={openIndexes.includes(index) ? closeIcon : openIcon} 
              alt="toggle visibility" 
              onClick={() => toggleVisibility(index)} 
              style={{ cursor: 'pointer', float: 'right' }} 
              className='Ang-icons'
            />
          </div>
          {openIndexes.includes(index) && (
            <div className='Angtyp-lft'>
              <img src={section.image} alt={section.title} className='Ang-img'/>
              <div className='Angulrtpe1-left2'>
                {section.content}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
    <div className='anglst-div'>
    <div className='get-div flex  bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${itembg})` }}>
      <div className='get-child'>
        <h1 className='get-childh1'>get quality bearings now!</h1>
        <p className='get-p'>ANK Overseas: Dedicated to quality, innovative. ANK Overseas will give the greatest degree of durability and performance.</p>
        <a href='/contactus'>
          <button className='get-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
            get quote
          </button>
        </a>
      </div>
    </div>
  </div>
  </>
  );
};

export default Angulartype1;
