import React from 'react'
import './HighTmpBear.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import highTmptemp from '../../../../../images/Products2/types/high temp cover.png'
const highTmpBear = () => {
  return (
    <div className='highTmp bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='highTmp-parent'>
          <div className='highTmp-lft'>
            <h1 className='highTmp-h2'>Overview</h1>
            <img src={highTmptemp} alt="Needle Bearing" className='highTmp-img'/>
          </div>
          <div className='highTmp-rght'>
            
         <p className='highTmp-p'>
         
         highTmp temperature bearings use specialized lubricants to stand up to intense heat.

Grease-packed bearings are pre-filled with fluorine grease for highTmp temperatures, 
while YS and SJ bearings use molybdenum disulfide (MoS2) solid lubricant to withstand temperatures up to 350°C and 400°C respectively.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default highTmpBear;