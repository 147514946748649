import React from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import './Ceramictypes.css';

const Ceramictypes = () => {


  const sections = [
    {
      content: (
        <>
          <div className='ceratype-list'>
            <h1>Features:</h1>
            <ul>
            <li><span>Fully Nonmagnetic:  </span>Ceramic materials do not generate magnetic fields, making them
            suitable for sensitive electronic environments.
            </li>
            <li><span>Excellent Resistance to Corrosion and Wear: </span>  
            Inert to most chemicals and resistant to
abrasive wear, ensuring longevity even in harsh conditions.
            </li>
            </ul>
            <h1>Applications:</h1>
            <ul>
            <li><span>Semiconductor Inspection Equipment:  </span> Utilized in the semiconductor industry where
            contamination control and precision are critical.
            </li>
            <li><span>Laser Inspection Equipment:   </span> Essential for maintaining accuracy and preventing
            interference in high-precision laser-based measurement systems.
            </li>         
             </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span> Low Maintenance: </span>  Self-lubricating cages reduce the need for frequent lubrication.
            </li>
            <li><span> High Precision:  </span> Maintains dimensional stability and accuracy in demanding
            applications.
            </li>
             </ul>
          </div>
        </>
      ),
    },


  ];

  return (
    <>
    <div className='ceratype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='cera-typh1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='cera-main'>
            <div className='cera-lft'>
                {section.content}
            </div>
        </div>
      ))}
    </div>
    <div className='ceramic-div'>
<div className='cera-divs flex  bg-center bg-cover bg-no-repeat  ' style={{ backgroundImage: `url(${itembg})` }}>

<div className='ceram-div' >
  <h1 className='ceram-divh1'>get quality bearings now!</h1>
  <p className='ceram-p'>ANK Overseas:Dedicated to quality,innovative . 
  ANK Overseas will give the greatest degree of
durability and performance.</p>
  <a href='/contactus'>
  <button className='ceramic-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
        get quote
       </button>
       </a>
</div>
</div>
</div>
    </>
  );
};

export default Ceramictypes;
