import React from 'react'
import './Cylindrical.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import CylindricalRoller1 from './Cylindrical/CylindricalRoller1.jsx';
import CylindricalType1 from './Cylindricaltypes/CylindricalType1.jsx';

const Cylindrical = () => {
  return (
    <div className='Cyld bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='Cyld-h1'>Cylindrical Roller Bearings</h1>
<CylindricalRoller1/>
<CylindricalType1/>
      </div>
  )
}

export default Cylindrical;