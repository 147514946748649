import React from 'react'
import './SelfAligning.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import Selfaling from './Selfaling/Selfaling.jsx';
import Selftypes1 from './Selftypes/Selftypes1.jsx';

const SelfAligning = () => {
  return (
    <div className='selfMain bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='selfMain-h1'>Self-Aligning Ball Bearings</h1>
     <Selfaling/>
     <Selftypes1/>
      </div>
  )
}

export default SelfAligning;