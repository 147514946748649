import React from 'react'
import './Ceramic.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import CeramicBear from './CeramicBear/CeramicBear.jsx';
import Ceramictypes from './Ceramictypes/Ceramictypes.jsx';

const Ceramic = () => {
  return (
    <div className='ceraMain bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='ceraMain-h1'>Ceramic Bearings</h1>
        <CeramicBear/>
        <Ceramictypes/>
      </div>
  )
}

export default Ceramic;