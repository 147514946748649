import React from 'react'
import Footer1 from '../Footer1/Footer1'
import Footer2 from '../Footer1/Footer2'

const Footer = () => {
  return (
    <div>
      <Footer1/>
      <Footer2/>
    </div>
  )
}

export default Footer