import React from 'react'
import bg  from  '../../../images/products/sec 2/bg.png';
import Sidebar from '../Sidebar/Sidebar';
import './Content.css';
import Deep from '../../../images/Products2/PNG/deep groove.png';
import content from '../../../images/Products2/PNG/Angular Ball.png';
import self from '../../../images/Products2/PNG/self aligning ball.png';
import thrust from '../../../images/Products2/PNG/thrust ball_.png';
import Cylin from '../../../images/Products2/PNG/cylindrical roller.png';
import tapered from '../../../images/Products2/PNG/TAPERERD_.png';
import spheric from '../../../images/Products2/PNG/spherical roller bearing.png';
import thrustroll from '../../../images/Products2/PNG/thrust roller bearing.png';
import needle from '../../../images/Products2/PNG/Needle Roller.png';
import hightemp from '../../../images/Products2/PNG/high temperature.png';
import ceramic from '../../../images/Products2/PNG/ceramic-bearing.png';
import corrosion from '../../../images/Products2/PNG/Corrosion Resistant.png';

const Content = () => {
  return (
    <div className='content bg-center bg-no-repeat bg-cover' style={{backgroundImage: `url(${bg})`}}>
      <div className='content-left'>
       
        <Sidebar/>
      </div>
      <div className='content-rght'>
      <h1 className='content-h1'>Bearings</h1>
      <p className='content-p1'>We source our bearings from reliable sources that are recognized for producing high-quality
items with precision. There are rigorous tests that each type of bearing must pass to ascertain
they match our criteria on strength and effectiveness. Here’s an overview of the diverse rcontente of
bearings we offer:</p>

        <div className='items2 flex flex-col-3 flex-wrap md:flex-row justify-center items-center mx-4 md:mx-9'>
<div className='items2-box   '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={Deep} 
      alt="Experience and Expertise" className='items2-pic items2-pic1'
    />
  </div>
  <h1 className='items2-h1'>Deep Groove Ball Bearings</h1>
  <a href='/products/deep-groove'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
        Know more
       </button>
       </a>
</div>

<div className='items2-box  '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={content} 
      alt="Comprehensive Services" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Angular Contact Ball Bearings</h1>
  <a href='/products/angular-ball'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>
<div className='items2-box '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={self} 
      alt="Customer-Centric Approach" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Self-Aligning Ball Bearings</h1>
  <a href='/products/self-aligning'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>


{/* </div> */}
{/* <div className='items2 flex flex-col-3 md:flex-row justify-center items-center mx-4 md:mx-9'> */}
<div className='items2-box '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={thrust} 
      alt="Customer-Centric Approach" className='items2-pic items2-pic1'
    />
  </div>
  <h1 className='items2-h1'>Thrust Ball Bearings</h1>
  <a href='/products/thrust-ball'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>

<div className='items2-box  '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={Cylin} 
      alt="Comprehensive Services" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Cylindrical Roller Bearings</h1>
  <a href='/products/cylindrical-roller'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>

<div className='items2-box '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={tapered} 
      alt="Customer-Centric Approach" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Tapered Roller Bearings</h1>
  <a href='/products/tapered-roller'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>

{/* </div> */}
{/* <div className='items2 flex flex-col-3 md:flex-row justify-center items-center mx-4 md:mx-9'> */}
<div className='items2-box   '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={spheric} 
      alt="Experience and Expertise" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Spherical Roller Bearings</h1>
  <a href='/products/spherical-roller'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>

<div className='items2-box  '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={thrustroll} 
      alt="Comprehensive Services" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Thrust roller Bearings</h1>
  <a href='/products/thrust-roller'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>
<div className='items2-box '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={needle} 
      alt="Customer-Centric Approach" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Needle Roller Bearings</h1>
  <a href='/products/needle-roller'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>


{/* </div> */}
{/* <div className='items2 flex flex-col-3 md:flex-row justify-center items-center mx-4 md:mx-9'> */}
<div className='items2-box   '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={hightemp} 
      alt="Experience and Expertise" className='items2-pic items2-pic1'
    />
  </div>
  <h1 className='items2-h1'>High Temperature Bearings</h1>
  <a href='/products/high-temperature'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>

<div className='items2-box  '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={ceramic} 
      alt="Comprehensive Services" className='items2-pic'
    />
  </div>
  <h1 className='items2-h1'>Ceramic <br/>Bearings</h1>
  <a href='/products/ceramic-bearings'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>
<div className='items2-box '>
  <div className="approch flex items-center justify-center w-full">
    <img 
      src={corrosion} 
      alt="Customer-Centric Approach" className='items2-pic items2-pic1'
    />
  </div>
  <h1 className='items2-h1'>Corrosion Resistant Bearings</h1>
  <a href='/products/corrosion-bearings'>
  <button className='content-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A]'>
         Know more
       </button>
       </a>
</div>

</div>

      </div>
    </div>
  )
}

export default Content;