import React from 'react'
import Navbar from '../../../Navbar/Navbar1.jsx';
import bg  from  '../../../../images/products/sec 1/bg.png';
import './Corrosion.css';
import Corrosiontypes from './Corrosiontypes/Corrosiontypes.jsx';
import CorrosionBear from './CorrosionBear/CorrosionBear.jsx';
const Corrosion = () => {
  return (
    <div className='coroMain bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
         <Navbar/>
         <h1  className='coroMain-h1'>Corrosion Resistant Bearings</h1>
         <CorrosionBear/>
         <Corrosiontypes/>
         {/* <a href='/products'>
         <button className='back-btn'>back</button>
         </a> */}
        </div>
  )
}

export default Corrosion;