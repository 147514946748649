import React from 'react'
import './ThrustRoller1.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import Thrus from '../../../../../images/Products2/types/thrust roller cover.png'
const ThrustRoller1 = () => {
  return (
    <div className='thrstRoler bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='thrstRoler-parent'>
          <div className='thrstRoler-lft'>
            <h1 className='thrstRoler-h2'>Overview</h1>
            <img src={Thrus} alt="" className='thrstRoler-img'/>
          </div>
          <div className='thrstRoler-rght'>
            
         <p className='thrstRoler-p'>
         Thrust ball bearings are specifically designed to handle axial loads and offer high
performance in applications where stability is crucial.<br/>
Thrust ball  Bearings are ideal for automotive, marine, and aerospace applications. 
They offer high axial load capacity and excellent stability,
making them suitable for heavy-duty operations where reliability and performance are crucial.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default ThrustRoller1;