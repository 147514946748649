import React, { useState } from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import type1 from '../../../../../images/Products2/types/Cylindrical roller thrust.png';
import type2 from '../../../../../images/Products2/types/Tapered_roller_thrust.png';
import type3 from '../../../../../images/Products2/types/Spherical_roller_thrust.png';
import openIcon from '../../../../../images/Products2/sidebar/plus.svg';
import closeIcon from '../../../../../images/Products2/sidebar/minus.svg';
import './Thrusttypes1.css';

const Thrusttypes1 = () => {
  const [openIndices, setOpenIndices] = useState([true, true, true]); // Initialize all sections as open

  const toggleVisibility = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    ); // Toggle the section open or close
  };

  const sections = [
    {
      title: 'Cylindrical Thrust Bearings',
      image: type1,
      content: (
        <>
          <p className='thrtrollRow-p1'>
            Cylindrical thrust roller bearings can handle high axial loads and are suitable for applications with heavy thrust loads.
          </p>
          <div className='thrtRoll-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Machine Tool Spindles: </span> Used in spindles requiring high precision.</li>
              <li><span>Gearboxes: </span> Common in gearboxes for high-load applications.</li>
              <li><span>Marine Propulsion Systems: </span> Suitable for marine propulsion systems.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>High Axial Load Capacity: </span> Handles high axial loads.</li>
              <li><span>Low Friction: </span> Operates smoothly with low friction.</li>
              <li><span>Durable Design: </span> Robust and durable design for heavy-duty applications.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Tapered Thrust Bearings',
      image: type2,
      content: (
        <>
          <p className='thrtrollRow-p1'>
            Tapered thrust roller bearings can handle high axial loads and some radial loads. They are designed for applications where both types of loads are present.
          </p>
          <div className='thrtRoll-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Automotive Transmissions: </span> Used in automotive transmissions for combined load applications.</li>
              <li><span>Heavy-Duty Machinery: </span> Suitable for heavy-duty machinery requiring high precision.</li>
              <li><span>Marine Applications: </span> Common in marine applications for combined load handling.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>High Load Capacity: </span> Handles high axial and radial loads.</li>
              <li><span>Durable Design: </span> Robust and durable design for heavy-duty applications.</li>
              <li><span>Combined Load Handling: </span> Suitable for applications with combined loads.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Spherical Thrust Bearings',
      image: type3,
      content: (
        <>
          <p className='thrtrollRow-p1'>
            Spherical thrust roller bearings can accommodate high axial loads and moderate radial loads. They are self-aligning, providing reliable performance under misalignment conditions.
          </p>
          <div className='thrtRoll-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Hydraulic Turbines: </span> Used in hydraulic turbines for high-load applications.</li>
              <li><span>Industrial Machinery: </span> Common in industrial machinery for combined load handling.</li>
              <li><span>Heavy-Duty Equipment: </span> Suitable for heavy-duty equipment requiring high precision.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>High Load Capacity: </span> Handles heavy axial and moderate radial loads.</li>
              <li><span>Self-Aligning: </span> Compensates for shaft misalignment and deflection.</li>
              <li><span>Reliable Performance: </span> Durable and reliable performance in heavy-duty applications.</li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className='trstRol1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <h1 className='trstRol1-h1'>Product List</h1>
        {sections.map((section, index) => (
          <div key={index} className='trstRol1-main'>
            <div className='TrstRoll-left1'>
              <h2 className='TrstRoll1-h2'>{section.title}</h2>
              <img 
                src={openIndices[index] ? closeIcon : openIcon} 
                alt="toggle visibility" 
                onClick={() => toggleVisibility(index)} 
                style={{ cursor: 'pointer', float: 'right' }} 
                className='trstrol-icons'
              />
            </div>
            {openIndices[index] && (
              <div className='TrstRols-lft'>
                <img src={section.image} alt={section.title} className='trstRol-image'/>
                <div className='TrstRols-lft2'>
                  {section.content}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='trstRol-div'>
        <div className='trstRol-divs flex bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${itembg})` }}>
          <div className='trstRoler-divs'>
            <h1 className='trstRoler-divsh1'>Get quality bearings now!</h1>
            <p className='trstRoler-p'>
              ANK Overseas: Dedicated to quality, innovation. ANK Overseas will give the greatest degree of durability and performance.
            </p>
            <a href='/contactus'>
              <button className='trstRoler-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
                Get quote
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thrusttypes1;
