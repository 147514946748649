import React from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import './Selftypes1.css';

const Selftypes1 = () => {


  const sections = [
    {
      content: (
        <>
          <div className='slftype-list'>
            <h1>Applications:</h1>
            <ul>
            <li><span>Conveyor Systems: </span>Applicable to conveyor systems where misalignments are frequent.
            </li>
            <li><span>Fans and Blowers: </span>  Suitable for blowers and fans with flexible shafts.
            </li>
            <li><span>Textile Machinery:  </span>  Common in textile machinery where highp-precision is commonly
            required.
            </li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>Misalignment Tolerance: </span>Can compensate for shaft misalignment and deflection.
            </li>
            <li><span>Low Friction:  </span> Operates smoothly with low friction.
            </li>
            <li><span>High Speed:</span> Suitable for high-speed applications.
            </li></ul>
          </div>
        </>
      ),
    },


  ];

  return (
    <>
    <div className='selftype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='slftypeh1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='slftype-main'>
            <div className='selftype-lft'>
                {section.content}
            </div>
        </div>
      ))}

    </div>
    <div className='self-div'>
    <div className='self3-div flex  bg-center bg-cover bg-no-repeat  ' style={{ backgroundImage: `url(${itembg})` }}>
    
    <div className='selfing-div ' >
      <h1 className='selfing-divh1'>get quality bearings now!</h1>
      <p className='selfing-p'>ANK Overseas:Dedicated to quality,innovative . 
      ANK Overseas will give the greatest degree of
    durability and performance.</p>
      <a href='/contactus'>
      <button className='selfing-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
            get quote
           </button>
           </a>
    </div>
    </div>
    </div></>
  );
};

export default Selftypes1;
