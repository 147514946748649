import React from 'react'
import './NeedleRoller1.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import needle from '../../../../../images/Products2/types/needle roller.png'
const NeedleRoller1 = () => {
  return (
    <div className='nedle bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='nedle-parent'>
          <div className='nedle-lft'>
            <h1 className='nedle-h2'>Overview</h1>
            <img src={needle} alt="Needle Bearing" className='nedle-img'/>
          </div>
          <div className='nedle-rght'>
            
         <p className='nedle-p'>
         Needle roller bearings have long, thin rollers, making them suitable for
applications with limited radial space. They provide high load carrying capacity relative to their
size.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default NeedleRoller1;