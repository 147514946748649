import React from 'react'
import './Tapered.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import TaperedRoller1 from './TaperedRoller/TaperedRoller1.jsx';
import TaperedType1 from './Taperedtypes/TaperedType1.jsx';

const Tapered = () => {
  return (
    <div className='Taped bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='Taped-h1'>Tapered Roller Bearings</h1>
        <TaperedRoller1/>
        <TaperedType1/>
      </div>
  )
}

export default Tapered;