import React from 'react'
import Contact1 from '../Contact1/Contact1'
import Home5 from '../../HomePage/Home5/Home5'

export const Contact = () => {
  return (
    <div>
      <Contact1/>
      <Home5/>
    </div>
  )
}
