import React  from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import './Needletypes1.css';

const Needletypes1 = () => {
  const sections = [
    {
      content: (
        <>
          <div className='nedl-list'>
            
            <h1>Applications:</h1>
            <ul>
            <li><span>Automotive Transmissions:  </span> Used in automotive transmissions for high-load
            applications.
             </li>
             <li><span>Aerospace Applications: </span> Common in aerospace components requiring high precision.
             </li>
             <li><span>Industrial Machinery:  </span>  Suitable for industrial machinery with limited radial space.
             </li>
             </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>High Load Capacity:   </span> Handles high loads relative to their size.
             </li>
             <li><span>Compact Design:  </span>  Suitable for applications with limited radial space.
             </li>
             <li><span> Low Friction:  </span>  Operates smoothly with low friction.
             </li>
             <li><span> High Efficiency: </span>   Provides efficient performance in high-load applications.
             </li>
            </ul>
          </div>
        </>
      ),
    },

  ];

  return (
    <>
    <div className='ndltype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='ndltpeh1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='ndltyp-main'>
            <div className='Ndtyp-lft'>
                {section.content}
            </div>
        </div>
      ))}
    </div>
    <div className='nedle-div'>
    <div className='needle-divs flex  bg-center bg-cover bg-no-repeat  ' style={{ backgroundImage: `url(${itembg})` }}>
    
    <div className='need-divs' >
      <h1 className='need-divh1'>get quality bearings now!</h1>
      <p className='need-p'>ANK Overseas:Dedicated to quality,innovative . 
      ANK Overseas will give the greatest degree of
    durability and performance.</p>
      <a href='/contactus'>
      <button className='need-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
            get quote
           </button>
           </a>
    </div>
    
    
    </div>
    </div>
    </>
  );
};

export default Needletypes1;
