import React from 'react';
import './Home1.css';
import whatsp from '../../../images/Homepage/home/whatsapp1.png';
import masage from '../../../images/Homepage/home/message1.png';
import mail from '../../../images/Homepage/home/mail1.png';
import bg from '../../../images/Homepage/home/bg.png';
import Navbar1 from '../../Navbar/Navbar1';

const Home1 = () => {
  return (
    <div className='home1 overflow-hidden' style={{ backgroundImage: `url(${bg})` }}>
      <Navbar1/>
      <div className='home-parent'>
        <div className='home-left'>
          <h1 className='home-h1'>
            <span>ANK Overseas</span> 
          </h1>
          <p className='home-p'>"Your Trusted Source for
          Quality Bearings in India"</p>
          <a href='/products'>
          <button className='home-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A] '>View More</button>
          </a>
        </div>
        <div className='home-right'>
        <a href="https://wa.me/9115576153">
    <div class='reach-icon reach-icon1 rounded-full bg-gradient-to-r from-[#059b04] to-[#70f94b]'>
        <div class='rounded-full px-3 py-3 reach-link'>
            <img src={whatsp} alt="WhatsApp" class='home-icons w-6'/>
        </div>
        <h1 class='reach-text'>Whatsapp</h1>
    </div>
</a>
<a href="sms:9115576153">
    <div class='reach-icon reach-icon2 rounded-full bg-gradient-to-r from-[#015eea] to-[#00c0fa]'>
        <div class='rounded-full px-3 py-3 reach-link'>
        <img src={masage} alt="Message" className='home-icons w-6'/>
        </div>
        <h1 class='reach-text'>Message</h1>
    </div>
</a>
<a href="mailto:contact@ankoverseas.com" > 
<div className='reach-icon reach-icon3 rounded-full bg-gradient-to-r from-[#ca0902] to-[#ff3b3e]'>
<div className=' rounded-full px-3 py-3 reach-link' > 
    <img src={mail} alt="Email" className='home-icons w-6'/></div>
    <h1 className='reach-text'>Mail on</h1>
    </div>
    </a>
        </div>
      </div>
    </div>
  );
}

export default Home1;
