import React from 'react'
import './ThrustBall.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import ThrustBall1 from './ThrustBall1/ThrustBall1.jsx';
import ThrustBallType1 from './ThrustBalltypes/ThrustBallType1.jsx';

const ThrustBall = () => {
  return (
    <div className='Thrst bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='Thrst-h1'>Thrust Ball Bearings</h1>
       <ThrustBall1/>
       <ThrustBallType1/>
      </div>
  )
}

export default ThrustBall;