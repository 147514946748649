import React from 'react'
import './AngularBall.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import ang from '../../../../../images/Products2/main-png/Angular contact-main.png'
const AngularBall = () => {
  return (
    <div className='ang bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='ang-parent'>
          <div className='ang-lft'>
            <h1 className='ang-h2'>Overview</h1>
            <img src={ang} alt="" className='ang-img'/>
          </div>
          <div className='ang-rght'>
            
         <p className='ang-p'>
          These bearings are engineered to support combined loads, which means they can
handle both radial and axial loads simultaneously. They are crucial in applications requiring high
precision and speed.These bearings are perfect for high-speed operations and are widely used in machinery
and equipment where precision is key.<br/>
These bearings are used in a wide range of applications, such as pumps, compressors, and high-speed machinery. 
They offer high precision and reliability even under high speeds.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default AngularBall;