import React, { useState } from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import type1 from '../../../../../images/Products2/types/single direction.png';
import type2 from '../../../../../images/Products2/types/Double-direction.png';
import openIcon from '../../../../../images/Products2/sidebar/plus.svg';
import closeIcon from '../../../../../images/Products2/sidebar/minus.svg';
import './ThrustBallType1.css';

const ThrustBallType1 = () => {
  const [openIndexes, setOpenIndexes] = useState([0, 1]); // Initially set all indexes as open

  const toggleVisibility = (index) => {
    setOpenIndexes((prevIndexes) => 
      prevIndexes.includes(index) 
        ? prevIndexes.filter((i) => i !== index) // Close the section if it's already open
        : [...prevIndexes, index] // Open the section if it's closed
    );
  };

  const sections = [
    {
      title: 'Single Direction Thrust Ball Bearings',
      image: type1,
      content: (
        <>
          <p className='rowThrst-p1'>Single direction thrust ball bearings are intended to support
axial loads in a single direction. They are made up of shaft and housing washers separated by a
set of balls.
</p>
          <div className='Thrsttype-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Automotive Steering Systems: </span> Used in steering systems to handle axial loads.</li>
              <li><span>Machine Tool Spindles: </span> Common in spindles requiring high precision.</li>
              <li><span>Pumps:  </span> Suitable for high-load pump applications.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>High Axial Load Capacity: </span> Handles high axial loads in one direction.</li>
              <li><span>Compact: </span> Space-saving design for compact applications.</li>
              <li><span>Easy Maintenance:  </span> Simple design allows for easy maintenance.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Double Direction Thrust Ball Bearings',
      image: type2,
      content: (
        <>
          <p className='rowThrst-p1'>Double-direction thrust ball bearings can readily withstand
axial stresses in both directions. They are made up of three rings (a shaft washer, a housing
washer, and an intermediate ring) with two sets of balls.</p>
          <div className='Thrsttype-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Vertical Pumps: </span> Used in vertical pumps requiring high axial load capacity.</li>
              <li><span>Marine Applications: </span> Common in marine propulsion systems.</li>
              <li><span>Crane Hooks:   </span> Suitable for heavy-duty crane hooks.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>Bidirectional Load Capacity: </span> Handles axial loads in both directions.</li>
              <li><span>Compact Design: </span> Space-saving design for compact applications.</li>
              <li><span>Easy Assembly:   </span> Simple design allows for easy assembly and maintenance.</li>
            </ul>
          </div>
        </>
      ),
    },
    // Add more sections as needed
  ];

  return (
    <>
    <div className='Thrsttype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='thsttyp-h1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='thrst-left'>
          <div className='thrst1-left1'>
            <h2 className='thrst1-h2'>{section.title}</h2>
            <img 
              src={openIndexes.includes(index) ? closeIcon : openIcon} 
              alt="toggle visibility" 
              onClick={() => toggleVisibility(index)} 
              style={{ cursor: 'pointer', float: 'right' }} 
              className='thrtBal-icons'
            />
          </div>
          {openIndexes.includes(index) && (
            <div className='thrst1-lft'>
              <img src={section.image} alt={section.title} className='thrst-pic'/>
              <div className='thrst1-left2'>
                {section.content}
              </div>
            </div>
          )}
        </div>
      ))}
     
    </div>
     <div className='thrst-div'>
     <div className='thrst-item flex bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${itembg})` }}>
       <div className='thstitem-divs'>
         <h1 className='thstitem-divsh1'>Get quality bearings now!</h1>
         <p className='thurst-p'>ANK Overseas: Dedicated to quality, innovative. ANK Overseas will give the greatest degree of
durability and performance.</p>
         <a href='/contactus'>
           <button className='thurst-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
             Get quote
           </button>
         </a>
       </div>
     </div>
   </div></>
  );
};

export default ThrustBallType1;
