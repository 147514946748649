import React from 'react'
import './CylindricalRoller1.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import Cylin from '../../../../../images/Products2/types/Cylindrical roller cover.png'
const CylindricalRoller1 = () => {
  return (
    <div className='Cyld bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='Cyld-parent'>
          <div className='Cyld-lft'>
            <h1 className='Cyld-h2'>Overview</h1>
            <img src={Cylin} alt="Cylindrical roller-img" className='Cyld-img'/>
          </div>
          <div className='Cyld-rght'>
            
         <p className='Cyld-p'>
         Known for their high radial load capacity, cylindrical roller bearings are designed to
provide robust performance in heavy-load conditions. Their unique structure allows for a larger
contact area between the rollers and the raceway.<br/>
These bearings are suitable for industrial machinery, electric motors, and gearboxes. 
They offer high load capacity, durability, and efficiency.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default CylindricalRoller1;