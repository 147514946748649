import React from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import './SphericalType1.css';

const SphericalType1 = () => {
  const sections = [
    {
      content: (
        <>
          <div className='sphtype-list'>
            <h1>Applications:</h1>
            <ul>
            <li><span>Heavy Machinery:  </span> Used in heavy machinery requiring high load capacity
             </li>
             <li><span>Mining Equipment:  </span> Common in mining equipment for combined load applications.
             </li>
             <li><span>Paper Mills:   </span> Suitable for paper mills requiring high precision.
             </li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>High Load Capacity: </span> Handles heavy radial and axial loads.
             </li>
             <li><span>Self-Aligning: </span> Compensates for shaft misalignment and deflection.
             </li>
             <li><span>Durable:    </span>Reliable performance in heavy-duty applications.
             </li>
            </ul>
          </div>
        </>
      ),
    },


  ];

  return (
    <>
    <div className='sphtype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='sphtpeh1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='sphtyp-main'>
            <div className='sptyp-lft'>
                {section.content}
            </div>
        </div>
      ))}
    </div>
    <div className='sphe-div'>
    <div className='sphel-divs flex  bg-center bg-cover bg-no-repeat  ' style={{ backgroundImage: `url(${itembg})` }}>
    
    <div className='sphelcl-div ' >
      <h1 className='sphelcl-divh1'>get quality bearings now!</h1>
      <p className='sphelcl-p'>ANK Overseas:Dedicated to quality,innovative . 
      ANK Overseas will give the greatest degree of
    durability and performance.</p>
      <a href='/contactus'>
      <button className='spheric-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
            get quote
           </button>
           </a>
    </div>
    </div>
    </div>
    </>
  );
};


export default SphericalType1;
