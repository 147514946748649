import React, { useState } from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import type1 from '../../../../../images/Products2/types/Single row cylindrical.png';
import type2 from '../../../../../images/Products2/types/double row cylindrical.png';
import type3 from '../../../../../images/Products2/types/four row cylindrical.png';
import openIcon from '../../../../../images/Products2/sidebar/plus.svg';
import closeIcon from '../../../../../images/Products2/sidebar/minus.svg';
import './CylindricalType1.css';

const CylindricalType1 = () => {
  const sections = [
    {
      title: 'Single-Row Cylindrical Roller Bearings',
      image: type1,
      content: (
        <>
          <p className='rowCylnd--p1'>Single row cylindrical roller bearings have one row of rollers and
          are designed to withstand heavy radial loads. They offer little friction and are ideal for
          high-speed applications.</p>
          <div className='Cylnd-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Electric Motors: </span> Common in high-speed electric motors.</li>
              <li><span>Machine Tools: </span> Used in machine tools for precise alignment.</li>
              <li><span>Wind Turbines: </span> Suitable for wind turbine applications requiring high load capacity.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>High Radial Load Capacity: </span> Handles high radial loads.</li>
              <li><span>Low Friction: </span> Operates smoothly with low friction.</li>
              <li><span>High Speed: </span> Suitable for high-speed applications.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Double Row Cylindrical Roller Bearings',
      image: type2, 
      content: (
        <>
          <p className='rowCylnd--p1'>
          Double row cylindrical roller bearings have two rows of rollers,
          which provide more radial load capacity and stiffness than single row bearings.
          </p>
          <div className='Cylnd-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Heavy Machinery: </span> Used in heavy machinery requiring high load capacity.</li>
              <li><span>Gearboxes: </span> Common in gearboxes for high-load applications.</li>
              <li><span>Rolling Mills: </span> Suitable for rolling mills requiring high precision.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>Increased Load Capacity: </span> Handles higher loads than single row bearings.</li>
              <li><span>High Rigidity: </span> Increased stiffness for heavy-duty applications.</li>
              <li><span>Longer Service Life: </span> Durable and reliable performance.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Four-Row Cylindrical Roller Bearings',
      image: type3, 
      content: (
        <>
          <p className='rowCylnd--p1'>
          Four row cylindrical roller bearings are designed for heavy-duty
          applications, providing very high radial load capacity. They are commonly used in large rolling
          mills.</p>
          <div className='Cylnd-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Rolling Mills: </span> Suitable for large rolling mills requiring high load capacity.</li>
              <li><span>Heavy-Duty Machinery: </span> Used in heavy-duty machinery requiring high precision.</li>
              <li><span>Mining Equipment: </span> Common in mining equipment for high-load applications.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>Extremely High Load Capacity: </span> Handles very high radial loads.</li>
              <li><span>High Rigidity: </span> Increased stiffness for heavy-duty applications.</li>
              <li><span>Durability: </span> Durable and reliable performance.</li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  const [openIndexes, setOpenIndexes] = useState(sections.map((_, index) => index)); // Initially set all indexes as open

  const toggleVisibility = (index) => {
    setOpenIndexes((prevIndexes) => 
      prevIndexes.includes(index) 
        ? prevIndexes.filter((i) => i !== index) // Close the section if it's already open
        : [...prevIndexes, index] // Open the section if it's closed
    );
  };

  return (
    <>
    <div className='Cylintype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='Cyltye-h1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='Cyl1-main'>
          <div className='Cyl1-left1'>
            <h2 className='Cyltye1-h2'>{section.title}</h2>
            <img 
              src={openIndexes.includes(index) ? closeIcon : openIcon} 
              alt="toggle visibility" 
              onClick={() => toggleVisibility(index)} 
              style={{ cursor: 'pointer', float: 'right' }} 
              className='Cyld-icons'
            />
          </div>
          {openIndexes.includes(index) && (
            <div className='Cyltye1-lft'>
              <img src={section.image} alt={section.title} className='Cylind-image'/>
              <div className='Cylin1-left2'>
                {section.content}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
    <div className='Cylin-div'>
    <div className='Cylind-divs flex  bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${itembg})` }}>
      <div className='Cylind-div'>
        <h1 className='Cylind-divh1'>get quality bearings now!</h1>
        <p className='Cylind-p'>ANK Overseas: Dedicated to quality, innovative. ANK Overseas will give the greatest degree of durability and performance.</p>
        <a href='/contactus'>
          <button className='cyl-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
            get quote
          </button>
        </a>
      </div>
    </div>
  </div>
  </>
  );
};

export default CylindricalType1;
