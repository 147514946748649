import React from 'react'
import './HighTemp.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import HighTmpBear from './HighTmpBear/HighTmpBear.jsx';
import Hightypes1 from './Hightypes/Hightypes1.jsx';

const HighTemp = () => {
  return (
    <div className='highMain bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='highMain-h1'>High Temperature Bearings</h1>
<HighTmpBear/>
<Hightypes1/>
      </div>
  )
}

export default HighTemp;