import React from 'react'
import Whyus1 from '../Whyus1/Whyus1'
import Whyus2 from '../Whyus2/Whyus2'
import Whyus2Cpy from '../Whyus2Cpy/Whyus2Cpy'
export const Whyus = () => {
  return (
    <div>
        <Whyus1/>
        <Whyus2/>
        <Whyus2Cpy/>
    </div>
  )
}
