import React from 'react';
import bg  from  '../../../images/products/sec 1/bg.png';
import './Products2.css';
import Navbar1 from '../../Navbar/Navbar1';
import Content from '../Content/Content';
const Products2 = () => {
  return (
    <div className='product-page bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar1/>
        <h1  className='Product1-h1'>Diverse product range</h1>
        <Content/>
    </div>
  );
};

export default Products2;
