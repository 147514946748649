import React from 'react'
import './Selfaling.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import self from '../../../../../images/Products2/types/Self-Aligning cover.png'
const Selfaling = () => {
  return (
    <div className='self bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='self-parent'>
          <div className='self-lft'>
            <h1 className='self-h2'>Overview</h1>
            <img src={self} alt="" className='self-img'/>
          </div>
          <div className='self-rght'>
            
         <p className='self-p'>
         Two rows of balls and a shared sphered raceway in the outer ring characterise
self-aligning ball bearings. Because of their ability to withstand shaft deflection and
misalignment, these bearings are perfect for situations where alignment is challenging.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default Selfaling;