import React from 'react'
import './ThrustBall1.css';
import bg from '../../../../../images/products/sec 2/bg.png';
import thrtBal from '../../../../../images/Products2/types/1Thrust Ball.png'
// import thrtBaltype1 from './thrtBaltypes/thrtBalType1.jsx';

const ThrustBall1 = () => {
  return (
    <div className='thrtBal bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='thrtBal-parent'>
          <div className='thrtBal-lft'>
            <h1 className='thrtBal-h2'>Overview</h1>
            <img src={thrtBal} alt="" className='thrtBal-img'/>
          </div>
          <div className='thrtBal-rght'>
            
         <p className='thrtBal-p'>
         Thrust ball bearings are specifically designed to handle axial loads and offer high
         performance in applications where stability is crucial.
        <br/>
          These bearings are used in automotive, marine, and aerospace applications. They offer  high axial load capacity with excellent stability.
          </p>
          </div>
          
        </div>
      </div>
  )
}

export default ThrustBall1;