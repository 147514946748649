import React, { useState } from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import bg from '../../../images/Homepage/form/bg_.png';
import './Home5.css';

const Card = (props) => (
  <div className="form-div flex items-center justify-center bg-[#021033] rounded-md pb-5 pt-12 rounded-lg w-11/12 max-w-11/12 mx-auto">
    {props.children}
  </div>
);

const Form = (props) => (
  <form className="fields space-y-2" onSubmit={props.onSubmit}>
    {props.children}
  </form>
);

const TextInput = (props) => (
  <div className="relative mb-1 sm:mb-2" style={{ width: '100%' }}>
    <input
      className={`block w-full px-4 py-2 text-base text-white bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent transition-all duration-200 ease-in-out ${props.focus || props.value ? 'bg-transparent pt-6' : 'pt-3'}`}
      type="text"
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      style={{ zIndex: 0 }}
      required
      autoComplete={props.autoComplete}
    />
    {props.error && <p className="text-red-500 text-xs mt-1">{props.error}</p>}
  </div>
);

const TextArea = (props) => (
  <div className="relative mb-2">
    <textarea
      className={`mt-4 block px-4 py-4 text-base text-white bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 z-0 focus:border-transparent transition-all duration-200 ease-in-out ${props.focus || props.value ? 'pt-6' : 'pt-3'}`}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      style={{ zIndex: 0 }}
      required
      autoComplete={props.autoComplete}
    />
    {props.error && <p className="text-red-500 text-xs mt-1">{props.error}</p>}
  </div>
);

const Button = (props) => (
  <button className='form-btn rounded-full bg-gradient-to-r from-[#CF100B] to-[#FA373A] mt-2 text-white text-lg font-bold'>
    {props.children}
  </button>
);

const Modal = ({ show, onClose, Message }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md text-center">
        <p className="mb-4">{Message}</p>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Home5 = () => {
  const [formData, setFormData] = useState({
    name: { id: 'name', name: 'name', value: '', focus: false, error: '', autoComplete: 'name', placeholder: 'full name' },
    email: { id: 'email', name: 'email', value: '', focus: false, error: '', autoComplete: 'email', placeholder: 'mail@gmail.com' },
    company: { id: 'company', name: 'company', value: '', focus: false, error: '', autoComplete: 'organization', placeholder: 'company name' },
    address: { id: 'address', name: 'address', value: '', focus: false, error: '', autoComplete: 'address-line1', placeholder: 'address' },
    product: { id: 'product', name: 'product', value: '', focus: false, error: '', autoComplete: 'off', placeholder: 'your product' },
    phoneNumber: { id: 'phoneNumber', name: 'phoneNumber', value: '', focus: false, error: '', autoComplete: 'tel', placeholder: '9368XXXXXX' }, // Placeholder without country code
    Message: { id: 'Message', name: 'Message', value: '', focus: false, error: '', autoComplete: 'off', placeholder: 'your message' },
  });

  const [showModal, setShowModal] = useState(false);

  const handleFocus = (e) => {
    const { name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: { ...prev[name], focus: true, error: '' },
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: { ...prev[name], focus: false },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: { ...prev[name], value, error: '' },
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      phoneNumber: {
        ...prev.phoneNumber,
        value: value,
        error: '',
      },
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: formData.name.value,
      email: formData.email.value,
      company: formData.company.value,
      address: formData.address.value,
      product: formData.product.value,
      phoneNumber: formData.phoneNumber.value, 
      Message: formData.Message.value,
    };

    let isValid = true;

    if (!validateEmail(data.email)) {
      setFormData((prev) => ({
        ...prev,
        email: { ...prev.email, error: 'Invalid email format' },
      }));
      isValid = false;
    }

    if (!formData.phoneNumber.value) {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: { ...prev.phoneNumber, error: 'Invalid phone number' },
      }));
      isValid = false;
    }

    if (!isValid) return;

    try {
      const config = {
        method: 'post',
        url: 'https://node.airaielectric.com/api/v1/auth/addFeedback',
        data,
      };
      const response = await axios(config);
      setShowModal(true);

      // Reset the form fields
      setFormData({
        name: { ...formData.name, value: '', focus: false, error: '' },
        email: { ...formData.email, value: '', focus: false, error: '' },
        company: { ...formData.company, value: '', focus: false, error: '' },
        address: { ...formData.address, value: '', focus: false, error: '' },
        product: { ...formData.product, value: '', focus: false, error: '' },
        phoneNumber: { ...formData.phoneNumber, value: '', focus: false, error: '' },
        Message: { ...formData.Message, value: '', focus: false, error: '' },
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className="home5" style={{ backgroundImage: `url(${bg})` }}>
      <div className="frm-div flex items-center justify-center">
        <div className="form-cont">
          <Card>
            <Form onSubmit={handleSubmit}>
              <h1 className="form-head text-3xl sm:text-4xl font-bold font-lato lg:mb-6 text-center text-white">Request a Quote Today</h1>
              <div className="field-grid grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextInput
                  {...formData.name}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="phone-field relative p-0" style={{ width: '100%' }}>
                  <PhoneInput
                    country={'in'}
                    value={formData.phoneNumber.value}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: formData.phoneNumber.name,
                      id: formData.phoneNumber.id,
                      required: true,
                      className: 'form-control block w-full px-8 text-base text-white bg-transparent border border-gray-300 rounded-md focus:border-none focus:ring-2 focus:ring-gray-500 focus:border-transparent transition-all duration-200 ease-in-out'
                    }}
                  />
                  {formData.phoneNumber.error && (
                    <p className="text-red-500 text-xs  px-3 phone-text ">{formData.phoneNumber.error}</p>
                  )}
                </div>
              </div>
              <div className="field-grid grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextInput
                  {...formData.email}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextInput
                  {...formData.company}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="field-grid grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextInput
                  {...formData.address}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextInput
                  {...formData.product}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <TextArea
                {...formData.Message}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <div className="flex justify-center">
                <Button>Submit</Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} Message="Message sent successfully!" />
    </div>
  );
};

export default Home5;
