import React from 'react'
import './DeepGroove.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import deep from '../../../../images/Products2/main-png/deep groove.png'
import Deeptype1 from './Deeptypes/DeepType1.jsx';
import DeepGroove1 from './DeepGroove1/DeepGroove1.jsx';

const DeepGroove = () => {
  return (
    <div className='Deep bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='Deep-h1'>Deep Groove Ball Bearings</h1>
        <DeepGroove1/>
        <Deeptype1/>
      </div>
  )
}

export default DeepGroove;