import React from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import './Hightypes1.css';

const Hightypes1 = () => {


  const sections = [
    {
      content: (
        <>
          <div className='hightype-list'>
            <h1>Features:</h1>
            <ul>
            <li><span>Long Life:  </span>  Suitable for rolling mills requiring high precision.
            </li>
            <li><span>Suited for High Temperatures:   </span> Can withstand operating temperatures significantly
            higher than standard bearings.
            </li>
            </ul>
            <h1>Applications:</h1>
            <ul>
            <li><span>Transport/Conveyance Equipment in Furnaces:  </span>Used in machinery that moves
            materials through high-temperature environments, such as metal or glass furnaces.
            </li>
            <li><span>High-Temperature Components in Office Equipment:</span>  Employed in devices like
copiers and printers that may experience elevated temperatures during operation.
            </li>         
               </ul>
            <h1>Advantages:</h1>
            <ul>
            <li><span>Durability:  </span>  Enhanced materials and lubricants extend the lifespan even under high
thermal stress.
            </li>
            <li><span>Performance Stability:  </span> Maintains consistent performance and reduces downtime due to
            bearing failure.
            </li>            
            </ul>
          </div>
        </>
      ),
    },


  ];

  return (
    <>
    <div className='hightype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
      <h1 className='high-typeh1'>Product List</h1>
      {sections.map((section, index) => (
        <div key={index} className='high-main'>
            <div className='high-lft'>
                {section.content}
            </div>
        </div>
      ))}
    </div>
    <div className='hightmp-div'>
    <div className='hghtmp-divs flex  bg-center bg-cover bg-no-repeat  ' style={{ backgroundImage: `url(${itembg})` }}>
    
    <div className='tmphgh-div ' >
      <h1 className='tmphgh-divh1'>get quality bearings now!</h1>
      <p className='tmpthgh-p'>ANK Overseas:Dedicated to quality,innovative . 
      ANK Overseas will give the greatest degree of
    durability and performance.</p>
      <a href='/contactus'>
      <button className='high-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
            get quote
           </button>
           </a>
    </div>
    
    
    </div>
    </div>
    </>
  );
};

export default Hightypes1;
