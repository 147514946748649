import React from 'react'
import './CorrosionBear.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import corrosion from '../../../../../images/Products2/types/corrosion.png';
const CorrosionBear = () => {
  return (
    <div className='corosion bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='corosion-parent'>
          <div className='corosion-lft'>
            <h1 className='corosion-h2'>Overview</h1>
            <img src={corrosion} alt="Needle Bearing" className='corosion-img'/>
          </div>
          <div className='corosion-rght'>
            
         <p className='corosion-p'>
         Highly corrosion-resistant bearings are used in tough environments where water, weak/strong 
         acids, alkali, or reactive gases are present.<br/>
Our stainless steel, hybrid, Molded-Oil™, and coated bearings stand up to moist and wet environments while 
our ceramic and ESZ bearings withstand weak acid and alkaline environments. For environments where strong acids
 and reactive gases are present, we offer ceramic bearings and Aqua-Bearings™.
</p>
          </div>
           <div className=''>

           </div>
        </div>
      </div>
  )
}

export default CorrosionBear;