import React from 'react'
import './TaperedRoller1.css';
import bg  from  '../../../../../images/products/sec 2/bg.png';
import taperd from '../../../../../images/Products2/types/tapered roller cover.png'
const TaperedRoller1 = () => {
  return (
    <div className='taperd bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='taperd-parent'>
          <div className='taperd-lft'>
            <h1 className='taperd-h2'>Overview</h1>
            <img src={taperd} alt="" className='taperd-img'/>
          </div>
          <div className='taperd-rght'>
            
         <p className='taperd-p'>
         Tapered roller bearings feature a cup and cone assembly. The cup is comprised of the outer ring and the cone assembly 
         consists of inner ring, rollers, and cage. 
         This bearing construction accommodates combined loads and provides low friction during operation. 
         By adjusting one single row tapered roller bearing against a second tapered roller bearing and applying a preload, a rigid bearing application can be achieved.
</p>
          </div>
          
        </div>
      </div>
  )
}

export default TaperedRoller1;