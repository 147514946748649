import React from 'react'
import './Thrust.css';
import bg  from  '../../../../images/products/sec 1/bg.png';
import Navbar from '../../../Navbar/Navbar1.jsx';
import ThrustRoller1 from './ThrustRoller/ThrustRoller1.jsx';
import Thrusttypes1 from './Thrusttypes/Thrusttypes1.jsx';

const Thrust = () => {
  return (
    <div className='trstRoler bg-center bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <Navbar/>
        <h1  className='trstRoler-h1'>Thrust roller Bearings</h1>
        <ThrustRoller1/>
      <Thrusttypes1/>
      </div>
  )
}

export default Thrust;