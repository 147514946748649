
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './Components/HomePage/Home/Home';
import Footer from './Components/Footer/Footer/Footer';
import { About } from './Components/About/About/About';
import { Whyus } from './Components/WhyUs/Whyus/Whyus';
import { Contact } from './Components/Contact/Contact/Contact';
import Home5 from './Components/HomePage/Home5/Home5';
import Products2 from './Components/Products2/Product2/Products2';
import DeepGroove from './Components/Products2/BearingsPage/Deepgroove/DeepGroove';
import Angular from './Components/Products2/BearingsPage/Angularball/Angular';
import SelfAligning from './Components/Products2/BearingsPage/SelfAligning/SelfAligning';
import ThrustBall from './Components/Products2/BearingsPage/Thrustball/ThrustBall';
import Cylindrical from './Components/Products2/Rollerbearing/Cylindrical/Cylindrical';
import Tapered from './Components/Products2/Rollerbearing/Tapered/Tapered';
import Spherical from './Components/Products2/Rollerbearing/Spherical/Spherical';
import Thrust from './Components/Products2/Rollerbearing/Thrust/Thrust';
import Needle from './Components/Products2/Rollerbearing/Needle/Needle';
import HighTemp from './Components/Products2/EnvBearings/HighTemp/HighTemp';
import Ceramic from './Components/Products2/EnvBearings/Ceramic/Ceramic';
import Corrosion from './Components/Products2/EnvBearings/Corrosion/Corrosion.jsx';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/whyus' element={<Whyus/>}/>
            <Route path='/products' element={<Products2/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/contactus' element={<Home5/>}/>
      <Route path='/products/deep-groove' Component={DeepGroove}/>
      <Route path='/products/angular-ball' Component={Angular}/>
      <Route path='/products/self-aligning' Component={SelfAligning}/>
      <Route path='/products/thrust-ball' Component={ThrustBall}/>
      <Route path='/products/cylindrical-roller' Component={Cylindrical}/>
      <Route path='/products/thrust-roller' Component={Thrust}/>
      <Route path='/products/tapered-roller' Component={Tapered}/>
      <Route path='/products/spherical-roller' Component={Spherical}/>
      <Route path='/products/needle-roller' Component={Needle}/>
      <Route path='/products/high-temperature' Component={HighTemp}/>
      <Route path='/products/ceramic-bearings' Component={Ceramic}/>
      <Route path='/products/corrosion-bearings' Component={Corrosion}/>
    </Routes>
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
