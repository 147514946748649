import React, { useState } from 'react';
import bg from '../../../../../images/products/sec 2/bg.png';
import itembg from '../../../../../images/products/sec 2/Rectangle.png';
import type1 from '../../../../../images/Products2/types/Single row tapered.png';
import type2 from '../../../../../images/Products2/types/double row tapered.png';
import type3 from '../../../../../images/Products2/types/four row tapered.png';
import openIcon from '../../../../../images/Products2/sidebar/plus.svg';
import closeIcon from '../../../../../images/Products2/sidebar/minus.svg';
import './TaperedType1.css';

const TaperedType1 = () => {
  const [openIndices, setOpenIndices] = useState([true, true, true]); // Initialize all sections as open

  const toggleVisibility = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    ); // Toggle the section open or close
  };

  const sections = [
    {
      title: 'Single-Row Tapered Roller Bearings',
      image: type1,
      content: (
        <>
          <p className='tapedrow-p1'>
            Single row tapered roller bearings can handle combined radial and axial loads. The rollers are conical in shape, allowing the bearing to manage higher loads.
          </p>
          <div className='tapedtype-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Automotive Wheel Hubs: </span> Common in automotive wheel hubs for high-load applications.</li>
              <li><span>Gearboxes: </span> Suitable for gearboxes requiring high precision.</li>
              <li><span>Industrial Machinery: </span> Used in industrial machinery for combined load applications.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>High Load Capacity: </span> Handles high radial and axial loads.</li>
              <li><span>Durable Design: </span> Robust and durable design for heavy-duty applications.</li>
              <li><span>Precision: </span> Suitable for precise applications.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Double Row Tapered Roller Bearings',
      image: type2,
      content: (
        <>
          <p className='tapedrow-p1'>
            Double row tapered roller bearings have two rows of rollers, providing higher load capacity and better rigidity compared to single row bearings.
          </p>
          <div className='tapedtype-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Heavy-Duty Gearboxes: </span> Common in heavy-duty gearboxes requiring high load capacity.</li>
              <li><span>Industrial Equipment: </span> Suitable for industrial equipment requiring high precision.</li>
              <li><span>Axle Systems: </span> Used in axle systems for combined load applications.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>Higher Load Capacity: </span> Handles higher loads than single row bearings.</li>
              <li><span>Increased Rigidity: </span> Increased stiffness for heavy-duty applications.</li>
              <li><span>Longer Service Life: </span> Durable and reliable performance.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: 'Four-Row Tapered Roller Bearings',
      image: type3,
      content: (
        <>
          <p className='tapedrow-p1'>
            Four row tapered roller bearings are used in heavy-duty applications where high radial and axial loads need to be accommodated. They provide maximum load capacity and stiffness.
          </p>
          <div className='tapedtype-list'>
            <h1>Applications:</h1>
            <ul>
              <li><span>Rolling Mills: </span> Suitable for rolling mills requiring high load capacity.</li>
              <li><span>Large Machinery: </span> Used in large machinery for combined load applications.</li>
              <li><span>Heavy-Duty Mining Equipment: </span> Common in heavy-duty mining equipment.</li>
            </ul>
            <h1>Advantages:</h1>
            <ul>
              <li><span>Extremely High Load Capacity: </span> Handles very high radial and axial loads.</li>
              <li><span>High Rigidity: </span> Increased stiffness for heavy-duty applications.</li>
              <li><span>Durability: </span> Durable and reliable performance.</li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className='tapedtype1 bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <h1 className='tapedtyp-h1'>Product List</h1>
        {sections.map((section, index) => (
          <div key={index} className='tapdtyp-main'>
            <div className='tapd1-left1'>
              <h2 className='tpdtype-h2'>{section.title}</h2>
              <img 
                src={openIndices[index] ? closeIcon : openIcon} 
                alt="toggle visibility" 
                onClick={() => toggleVisibility(index)} 
                style={{ cursor: 'pointer', float: 'right' }} 
                className='Taperd-icons'
              />
            </div>
            {openIndices[index] && (
              <div className='Tpdtyp-lft'>
                <img src={section.image} alt={section.title} className='Tped-imgs'/>
                <div className='tpedtyp1-left2'>
                  {section.content}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='tped-div'>
        <div className='taped-divs flex bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${itembg})` }}>
          <div className='tpdchld-divs'>
            <h1 className='tpdchld-divsh1'>Get quality bearings now!</h1>
            <p className='tpdivs-p'>
              ANK Overseas: Dedicated to quality, innovation. ANK Overseas will give the greatest degree of durability and performance.
            </p>
            <a href='/contactus'>
              <button className='tpd-btn rounded-full bg-gradient-to-r from-[#c8c9c9] via-[#f5efef] to-[#f4efef]'>
                Get quote
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaperedType1;
